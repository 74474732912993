const email = 'qzehdxigrjmi.ztq@ta';

function showContactAdress() {
  const p = document.querySelector('.contact');
  p.innerHTML += `Contact: <a href="mailto:${decipher(email)}?subject=L.O.W">${decipher(email)}</a>`
}

function decipher(txt) {
  const alpha = 'abcdefghijklmnopqrstuvwxyz@-.';
  const cipher = 'hsu@anwmopdfrqyit-xcbvzlke.gj';
  return txt.replace(/[a-z@\-\.]/gi, l => cipher[alpha.indexOf(l)]);
}

showContactAdress();